<template>
    <div class="alert-report__timeline">
        <div class="alert-report__event" v-for="(location, index) in locations" :key="index"
            @click="emitCords(location)">
            <div class="alert-report__event-line"></div>
            <div class="alert-report__event-info">
                <div class="alert-report__event-background-icon">
                    <img src="@/assets/icons/fluent-emoji-high-contrast_sos-button.svg" v-if="location.eventId == 1" />
                    <img src="@/assets/icons/eva_pin-fill.svg" v-if="location.eventId == 2" />
                    <img src="@/assets/icons/bx_qr.svg" v-if="location.eventId == 3" />
                    <img src="@/assets/icons/vuesax/linear/refresh-2-red.svg" v-if="location.eventId == 4" />
                </div>
                <div class="alert-report__event-text">
                    <div class="alert-report__type-event">
                        {{ location.eventType }}
                    </div>
                    <div class="alert-report__event-location" v-if="location.lastLocation">
                        <img class="alert-report__event-text-icon" src="@/assets/icons/eva_pin-fill-white.svg" />
                        {{ location.lastLocation }} - {{ location.lastLocationFloor }}
                    </div>
                    <div class="alert-report__event-location" v-else>
                        <img class="alert-report__event-text-icon" src="@/assets/icons/eva_pin-fill-white.svg" />
                        No Last Location
                    </div>
                    <div class="alert-report__event-time">
                        <img class="alert-report__event-text-icon" src="@/assets/icons/ri_time-line.svg" />
                        {{ location.locationDate }} {{ location.locationTime }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue'

const props = defineProps(['locations'])
const emits = defineEmits(['location'])

watch(() => props.locations, (cords) => {
    let lastCords = ""
    let index = 0

    while (lastCords === "") {
        if (cords[index].lat && cords[index].lng) lastCords = cords[index]

        index++
    }

    const cordsObj = { lat: parseFloat(lastCords.lat), lng: parseFloat(lastCords.lng) }

    emits('location', cordsObj)
})

function emitCords(cords) {
    if (cords.lat && cords.lng) {
        let cordsObj = { lat: parseFloat(cords.lat), lng: parseFloat(cords.lng) }
    
        emits('location', cordsObj)
    }
}
</script>